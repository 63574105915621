<template>
  <v-flex class="item md6 sm6 xs12">
    <div>
      <div class="btn-upload fal fa-cloud-upload">
        <input
          type="file"
          :multiple="isMultiple"
          :name="fileType"
          @change="filesChange($event)"
          :accept="acceptedExtensions"
        >
      </div>
      <h4>{{ title }}</h4>
      <p>{{ text }}</p>
      <ul class="files">
        <li v-for="item in files.filter(filterByType.bind(this, fileType))" :key="item.Order">
          <i class="fal fa-file-pdf text-red-dark" v-if="item.file.extension.includes('pdf')"></i>
          <i class="fal fa-file-image text-orange" v-else></i>
          <a href="#" @click="openConfirmDeleteFileProjectModal(item.id)">
            <Icon class="fal fa-trash"/>
          </a>
          {{item.file.name}}
          <span>{{formatFileSize(item.file.size)}}</span>
        </li>
      </ul>
      <AucAlert
        ref="confirmationComponent"
        title="Você tem certeza que deseja excluir esse arquivo?"
        confirmButtonText="Sim"
        cancelButtonText="Não"
        @confirm="deleteFile(selectedFileProjectId)"
      />
    </div>
  </v-flex>
</template>

<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import { FileType } from "@/scripts/models/enums/fileType.enum";
import FileProject from "@/scripts/models/fileProject.model";
export default {
  components: {
    AucAlert
  },
  props: {
    title: "",
    text: "",
    acceptedExtensions: "",
    files: Array,
    isMultiple: false,
    fileType: null
  },
  data() {
    return {
      selectedFileProjectId: null
    };
  },
  methods: {
    openConfirmDeleteFileProjectModal(id) {
      this.selectedFileProjectId = id;
      this.$refs.confirmationComponent.open();
    },
    filesChange(event) {
      var fileProject = new FileProject();
      var reader = new FileReader();

      var file = event.target.files[0];
      fileProject.file.name = file.name;
      fileProject.type = event.target.name;

      reader.onload = function() {
        fileProject.file.path = reader.result;
        this.$emit("upload", fileProject);
      }.bind(this);
      reader.readAsDataURL(file);
    },
    filterByType(currentType, item) {
      return item.type === currentType;
    },
    formatFileSize(size) {
      if (size / 1024 < 999) {
        return (size / 1024).toFixed(1) + "kb";
      } else {
        return (size / 1024 / 1024).toFixed(1) + "mb";
      }
    },
    deleteFile(fileProjectId) {
      this.$emit("delete", fileProjectId);
    }
  }
};
</script>