import File from "@/scripts/models/file.model";
export default class FileProject {

    constructor() {
        this.Order = 0;
        this.id = 0;
        this.projectId = 0;
        this.file = new File();
        this.type = 0;
    }
}