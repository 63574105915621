<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>Documentos</h1>
        </div>

        <!-- boxapp body -->
        <div class="box-main">
          <div class="section document-grid">
            <!-- docs grid-->
            <v-layout wrapper wrap>
              <ProjectDocument
                title="Projeto completo aprovado com orçamento [PDF]"
                text="Faça ‘upload’ do(s) arquivo(s) do projeto aprovado, incluindo o orçamento."
                acceptedExtensions="application/pdf"
                :files="fileProjectList"
                :fileType="fileType.ProjetoCompletoAprovado"
                :isMultiple="false"
                @upload="save"
                @delete="deleteFile"
              />

              <ProjectDocument
                title="Apresentação [PDF]"
                text="Faça ‘upload’ do arquivo de apresentação do projeto."
                acceptedExtensions="application/pdf"
                :files="fileProjectList"
                :fileType="fileType.Apresentacao"
                :isMultiple="false"
                @upload="save"
                @delete="deleteFile"
              />

              <ProjectDocument
                title="Documento Aprovação / Certificação Captação [PDF]"
                text="Faça ‘upload’ do(s) arquivo(s) de documentação formal, que comprove(m) a aprovação do projeto (Publicação no diário Oficial; Certificado de Autorização para Captação; etc)."
                acceptedExtensions="application/pdf"
                :files="fileProjectList"
                :fileType="fileType.DocumentoAprovacao"
                :isMultiple="false"
                @upload="save"
                @delete="deleteFile"
              />

              <ProjectDocument
                title="Comprovante de conta bancária [PDF, PNG, JPG]"
                text="Faça ‘upload’ do arquivo, contendo uma cópia do cabeçalho de extrato da conta do projeto ou de outro documento emitido pelo banco, que ateste os dados da conta bancária do projeto."
                acceptedExtensions="application/pdf, image/jpeg, image/png"
                :files="fileProjectList"
                :fileType="fileType.ComprovanteContaBancaria"
                :isMultiple="false"
                @upload="save"
                @delete="deleteFile"
              />
            </v-layout>
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <v-flex class="align-center justify-end footer-step-modal full-screen">
        <v-btn
          flat
          round
          color="white"
          class="d-flex btn btn-primary px-5"
          large
          @click="backToList()"
        >Concluir</v-btn>
      </v-flex>
      <HelpBox :text="helpBoxText" />
    </v-layout>
  </div>
</template>

<script type="plain/text">
import FileProjectService from "@/scripts/services/fileProject.service";
import FileProject from "@/scripts/models/fileProject.model";
import ProjectDocument from "@/components/ProjectDocument.vue";
import { FileType } from "@/scripts/models/enums/fileType.enum";
import { Routes } from "@/scripts/models/enums/routes.enum";
import HelpBox from "@/components/HelpBox.vue";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";

export default {
  components: {
    HelpBox,
    ProjectDocument
  },

  data() {
    return {
      helpBoxText: HelpBoxText.Documents,
      project: Object,
      selectedFileProjectI: null,
      fileProjectService: new FileProjectService(),
      fileProjectList: [],
      routes: Routes,
      fileType: FileType,
      projectId: null
    };
  },
  created() {
    this.projectId = this.$route.query.projectId;
    this.fileProjectService.listByProjectId(
      this.listFileProjectCallback,
      this.projectId
    );
  },
  methods: {
    backToList(){
      this.$router.push({name: Routes.app.ProjectList})
    },
    listFileProjectCallback(data) {
      this.fileProjectList = data;
    },
    saveCallback(data) {
      this.fileProjectList = data;
    },
    deleteFileProjectCallback() {
      this.fileProjectService.listByProjectId(
        this.listFileProjectCallback,
        this.projectId
      );
    },
    save(object) {
      object.projectId = this.projectId;
      this.fileProjectService.save(this.saveCallback, object);
    },
    deleteFile(fileProjectId) {
      this.fileProjectService.deleteFile(
        this.deleteFileProjectCallback,
        fileProjectId
      );
    }
  }
};
</script>