<template>
    <a href="#" class="chatbot">
        <Icon name="comment" size="24" colorFont="#02ACB4"></Icon>
    </a>
</template>

<script type="plain/text">
import Icon from '@/components/Icon.vue'
export default {
    components:{
        Icon
    }
}
</script>

<style lang="stylus" scoped>
.chatbot
    width 70px
    height 70px
    background-color white
    border-radius 50%
    display flex
    justify-content center
    align-items center
    position absolute
    bottom 30px
    right 30px
</style>
