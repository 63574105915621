import BaseCrudService from "./baseCrud.service";

export default class FileProjectService extends BaseCrudService {

	constructor() {
		super('FileProject');
	}

	deleteFile(callback, fileProjectId) {
		this.deleteRequest('Delete', { id: fileProjectId }).then(callback);
	}

	listByProjectId(callback, projectId) {
		this.getRequest('ListByProjectId', { projectId: projectId }).then(callback);
	}

	listByProjectIdAndType(callback, projectId, fileType) {
		this.getRequest('ListByProjectIdAndType', { projectId: projectId, fileType: fileType}).then(callback);
	}

	save(callback, data) {
		this.postRequest('Save', data).then(callback);
	}
}