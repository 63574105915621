<template>        
        <div class="box-help">
            <a class="btn-help" @click="showMenu"></a>
            <div class="scroll" v-html="text"></div>
        </div>
</template>

<script type="plain/text">
import ChatBot from '@/components/ChatBot.vue'
export default {
    props:{
        text: ''
    },
    components:{
        ChatBot
    },
    data(){
        return{
            showmenu: true
        }
    },
    methods:{
        showMenu(){
            this.showmenu ? document.body.classList.add('help-active') : document.body.classList.remove('help-active')
            this.showmenu = !this.showmenu
        }
    }
} 
</script>