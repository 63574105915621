const FileType = {
	ProjetoCompletoAprovado: 1,
	Apresentacao: 2,
	DocumentoAprovacao: 3,
	ComprovanteContaBancaria: 4,
	ImagemGaleriaProjeto: 5,
	ImagemDepoimentoCampanha: 6,
	ImagemNoticiaCampanha: 7,
	ImagemParceiroCampanha: 8,
	ImagemGaleriaCampanha: 9,
	ImagemCasoCampanha: 10,
	ImagemPassoDoacaoCampanha: 11,
	ImagemNoticiaProjeto: 12
};

export { FileType };